.grid {
    display: grid;
}

.main-grid {

    width: 100%;
    padding: 0 20px;

    @media (min-width: $sm) {
        grid-template-columns: auto 800px auto;
    }
}
