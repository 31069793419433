.login {
    // Vars

    // Support

    // Module
    & {
        position: fixed;
        z-index: 5000;
        display: grid;
        grid-template-columns: 20px auto 20px;
        grid-template-rows: auto auto auto;
        width: 100vw;
        height: 100vh;
        background-color: rgba(138, 29, 158, 1);

        @media (min-width: $s) {
            grid-template-columns: auto 500px auto;
        }

        label {
            font-size: 1.6rem;
            display: block;
            padding-bottom: 10px
        }

        input {
            box-sizing: border-box;

            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            border: none;
            padding: 10px;
            border-radius: 5px;
            color: white;
            font-size: 1.8rem;
            margin-top: 8px;
            margin-bottom: 30px;

            &::placeholder {
                color: rgba(255, 255, 255, 0.5);
            }

            &:hover {
                outline-width: 0;
                background: rgba(0, 0, 0, 0.3);

            }

            &:focus {
                outline-width: 0;
                background: rgba(0, 0, 0, 0.5);

            }
        }

        form {
            grid-column: 2/3;
            grid-row: 2/3;
            align-self: center;
        }

        .login--button-group {
            display: flex;
            justify-content: space-between;
        }

        .login--cancel {
            margin-top: 20px;
            width: 100%;
            margin-right: 20px;
            background-color: transparent;
            box-shadow: none;
            text-align: center;

            &:hover {
                background-color: rgba(0, 0, 0, 0.08);
                box-shadow: none;
            }

            &:active {
                background: rgba(0, 0, 0, 0.1);
                box-shadow: none;
            }
        }

        .login--button {
            display: block;
            margin-top: 20px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);

            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }

    // Facets

    // States
}
