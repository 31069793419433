.contact {
    // Vars

    // Support

    // Module
    & {
        margin-top: -66%;
        padding-bottom: 8%;
        overflow: hidden;

        .contact--spacer {
            width: 100%;
            height: 100%;
            padding-top: 70%;

            @media(min-width: $sm) {
                padding-top: 60%;
            }
            @media (min-width: $lg) {
                padding-top: 50%;
            }
        }

        .contact--title {
            text-align: center;
            @media (min-width: $sm) {
                grid-column: 2/3;
            }
        }

        .contact--container {
            z-index: 1000;
        }

        .contact--content {
            margin-top: 50px;
            @media (min-width: $sm) {
                grid-column: 2/3;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 50px;
            }
        }

        .contact--before, &:after {
            content: ' ';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding-top: 100%;
        }

        .contact--before-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        .contact--content-bg {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .contact--before {
            background: #344b5e;
            background: radial-gradient(#ff52f2, #271933);
            mask-image: url(../../Media/alpha_004.png);
            mask-size: cover;
            mask-repeat: no-repeat;
        }

        &:after {
            background-image: url(../../Media/schatten_04.png);
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
        }
    }

    // Facets

    // States
}
