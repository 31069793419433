.colorpicker {
    // Vars

    // Support

    // Module
    & {
        position: fixed;
        top: 50vh;
        left: 20px;
        z-index: 5000;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-column-gap: 10px;

        .colorpicker--box {
            width: 150px;
            height: 150px;

            &::before, &::after {
                content: ' ';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            &::before {
                background: linear-gradient(-90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            }

            &::after {
                background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            }

            .colorpicker--box-picker {
                cursor: pointer;
                position: absolute;
                width: 10px;
                height: 10px;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                border: 1px solid black;
                transform: translate(-50%, -50%);
                z-index: 1000;
            }
        }

        .colorpicker--panel {
            width: 25px;
            height: 150px;
            background: linear-gradient(rgb(255, 0, 0) 0%, rgb(255, 255, 0) 16.66%, rgb(0, 255, 0) 33.32%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 66.64%, rgb(255, 0, 255) 83.3%, rgb(255, 0, 0) 100%);

            .colorpicker--panel-slider {
                cursor: pointer;
                position: absolute;
                height: 5px;
                width: 110%;
                top: 0px;
                left: 50%;
                transform: translate(-50%, -50%);

                &::before {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    height: 2px;
                    background-color: black;
                }
            }
        }

        .colorpicker--save {
            margin-top: 10px;
        }

        .colorpicker--reset {
            grid-row: 3;
            margin-top: 10px;
        }
    }

    // Facets
    & {
        &.colorpicker__outer {
            right: 20px;
            left: auto
        }
    }

    // States
}
