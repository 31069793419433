.contact-form {
    // Vars

    // Support

    // Module
    & {
        .contact-form--label {
            font-size: 1.6rem;
        }

        .contact-form--title{
            margin-bottom: 50px;
        }

        .contact-form--input, .contact-form--textarea {
            box-sizing: border-box;

            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            border: none;
            padding: 10px;
            border-radius: 5px;
            color: white;
            font-size: 1.8rem;
            margin-top: 8px;
            margin-bottom: 30px;

            &::placeholder {
                color: rgba(255,255,255,0.5);
            }

            &:hover {
                outline-width: 0;
                background: rgba(0, 0, 0, 0.3);

            }

            &:focus {
                outline-width: 0;
                background: rgba(0, 0, 0, 0.5);

            }
        }

        .contact-form--textarea {
            height: 200px;
            resize: none;
            @include dinMed();
        }

        .contact-form--error {
            font-size: 1.6rem;
            margin-top: -30px;
            margin-bottom: 20px;
        }

        &.contact-form--abort {
            align-self: center;
        }

    }

    // Facets

    & {
        &.contact-form__success {
            align-self: center;
        }
    }

    // States
}
