.address {
    // Vars

    // Support

    // Module
    & {
        font-style: normal;
        margin-top: 80px;
        padding-bottom: 50px;

        @media (min-width: $sm) {
                 margin-top: 150px;
        }

        @media (min-width: $sm) {
            margin-top: 20px;
        }

        .address--body {
            white-space: pre-wrap;
            padding-top: 20px;
        }

        .address--second-title {
            padding-top: 50px;
        }

        .address--phone {
            padding-top: 20px;
        }

        .address--container {
            padding-top: 20px;
        }

        .address--contact {
            white-space: pre-wrap;
            padding-top: 50px;
        }


    }

    // Facets

    // States
}
