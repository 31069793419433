.method {
    // Vars

    // Support

    // Module
    & {
        margin-top: -66%;
        padding-bottom: 80%;
        overflow: hidden;

        .method--spacer {
            width: 100%;
            height: 0;
            padding-top: 70%;

            @media(min-width: $sm) {
                padding-top: 60%;
            }
            @media (min-width: $lg) {
                padding-top: 50%;
            }
        }

        .method--content {
            grid-column: 2/3;
        }

        .method--content-title {
            margin-bottom: 30px;
        }

        .method--quote {
            white-space: pre-wrap;
            font-style: italic;
            padding-bottom: 40px;

            padding-left: 20px;
            padding-right: 20px;
            @media (min-width: $sm) {
                padding-left: 100px;
            }
        }

        .method--body {
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            padding-bottom: 40px;
            padding-left: 20px;

            @media (min-width: $sm) {
                padding-left: 100px;
            }
        }

        .method--before, .method--after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding-top: 100%;
        }

        .method--before {
            background: #344b5e;
            background: radial-gradient(#e465ac, #170015);
            mask-image: url(../../Media/alpha_004.png);
            mask-size: cover;
            mask-repeat: no-repeat;
        }

        .method--before-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        .method--content-bg {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .method--after {
            background-image: url(../../Media/schatten_04.png);
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
        }

        .method--article-title {
            white-space: pre-wrap;
            @media (min-width: $sm) {
                padding-left: 20px;
                padding-right: 20px;
                padding-left: 100px;
            }
        }
    }

    // Facets

    // States
}
