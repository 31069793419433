@font-face {
    font-family: "DIN-Reg";
    src: url("../fonts/DIN-Regular.woff2") format("woff2"), url("../fonts/DIN-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DIN-Med";
    src: url("../fonts/DIN-Medium.woff2") format("woff2"), url("../fonts/DIN-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@mixin dinReg() {
    font: {
        family: "DIN-Reg", Arial;
        weight: normal;
        style: normal;
    }
}

@mixin dinMed() {
    font: {
        family: "DIN-Med", Arial;
        weight: normal;
        style: normal;
    }
}
