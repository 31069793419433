.about {
    // Vars

    // Support

    // Module
    & {
        overflow: hidden;

        margin-top: -66%;

        padding-bottom: 70%;

        .about--spacer {
            width: 100%;
            height: 100%;
            padding-top: 70%;

            @media(min-width: $sm) {
                padding-top: 60%;
            }
            @media (min-width: $lg) {
                padding-top: 50%;
            }
        }

        .about--portrait {
            position: relative;
            grid-row: end;
            grid-column: 2;
            width: 100%;
            padding-top: 149%;
            margin-top: -10px;
            margin-right: -20px;
            margin-left: auto;
            pointer-events: none;
            justify-self: center;

            .about--portrait-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }


            @media (min-width: $sm) {
                padding-top: 0;
                margin-top: -20px;
                width: auto;
                height: 100%;

                .about--portrait-img {
                    position: relative;
                    width: auto;
                    height: 1450px;
                }
            }

            @media (min-width: $md) {
                .about--portrait-img {
                    height: 1510px;
                }
                margin-top: -420px;
                margin-left: 0;
                margin-right: -20px;
                grid-row: end;
                grid-column: auto / 4;
                justify-self: right;
            }
        }

        .about--container {
            @media (min-width: $md) {
                grid-template-columns: auto 800px 35%;
            }
        }

        .about--content {
            white-space: pre-wrap;
            grid-column: 2/3;
        }

        .about--content-title {
            margin-bottom: 30px;
        }

        .about--article-title, .about--content-text, .about--content-quote {
            padding-left: 20px;
            @media (min-width: $sm) {
                padding-left: 100px;
                padding-right: 20px;
            }
        }

        .about--content-quote {
            white-space: pre-wrap;
            font-style: italic;
            padding-top: 20px;
        }

        .about--before, &:after {
            content: ' ';
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding-top: 100%;
        }

        .about--before-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        .about--content-bg {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .about--before {
            background: #344b5e;
            background: radial-gradient(#9326b7, #000321);
            mask-image: url(../../Media/alpha_002.png);
            mask-size: cover;
            mask-repeat: no-repeat;
        }

        &:after {
            background-image: url(../../Media/schatten_02.png);
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
        }
    }

    // Facets

    // States
}
