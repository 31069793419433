.events {
    // Vars

    // Support

    // Module
    & {
        margin-top: -66%;
        padding-bottom: 70%;
        overflow: hidden;

        .events--spacer {
            width: 100%;
            height: 0;
            padding-top: 70%;

            @media(min-width: $sm) {
                padding-top: 60%;
            }
            @media (min-width: $lg) {
                padding-top: 50%;
            }
        }

        .events--container {
            z-index: 1000;
        }

        .events--title {
            text-align: center;
            @media (min-width: $sm) {
                justify-self: center;
                grid-column: 2/3;
            }
        }

        .events--content {
            margin-top: 100px;
            @media (min-width: $sm) {
                margin-top: 150px;
                grid-column: 2/3;
                display: grid;
                grid-template-columns: max-content;
                justify-content: center;
            }
        }

        .events--empty-slot {
            display: none;
            @media (min-width: $sm) {
                display: block;
            }
        }

        .events--date-sep {
            grid-column: 1/2;
            grid-row: 1/2;
            @media (min-width: $sm) {
                grid-column: auto;
                grid-row: auto;
            }
            justify-self: center;
            background: #f3ed8a;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            align-self: center;
        }

        .events--date {
            width: 100%;

            display: grid;
            grid-template-columns: 30px 1fr;
            grid-column-gap: 25px;
            margin-bottom: 0px;

            @media (min-width: $sm) {
                grid-column-gap: 35px;
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                width: 800px;
            }
        }

        .events--date-title {
            line-height: 1;
            grid-column: 2/3;
            grid-row: 1/2;
            @media (min-width: $sm) {
                grid-column: auto;
                grid-row: auto;
            }
        }

        .events--date-text {
            white-space: pre-wrap;
            grid-column: 2/3;
            grid-row: 2/3;
            padding-bottom: 60px;
            padding-top: 10px;
            @media (min-width: $sm) {
                grid-column: auto;
                grid-row: auto;
            }
        }

        .events--line-wrapper {
        }

        .events--line {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            min-width: 100%;
            height: 100%;
            stroke: currentColor;
            stroke-width: 2px;
            fill: none;
        }

        .events--before, &:after {
            content: ' ';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding-top: 100%;
        }

        .events--before-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        .events--content-bg {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .events--before {
            background: #344b5e;
            background: radial-gradient(#73cece, #012727);
            mask-image: url(../../Media/alpha_003.png);
            mask-size: cover;
            mask-repeat: no-repeat;
        }

        &:after {
            background-image: url(../../Media/schatten_03.png);
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
        }

        .events--delete {
            position: absolute;
            top: -70px;

            @media (max-width: $sm) {
                position: relative;
                margin-top: 20px;
                margin-bottom: 15px;
                top: 0;
            }
        }


        .events--add {
            position: absolute;
            bottom: -80px;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
        }


    }

    // Facets

    & {
        .events--date {
            &.events--text__right {
                text-align: left;
                @media (min-width: $sm) {
                    text-align: right;
                }
            }
        }
    }

    // States
}
