.nav {
    // Vars

    // Support

    // Module
    z-index: 1001;
    position: fixed;

    & {
        .nav--menu {
            display: none;
            position: fixed;
            width: 100vw;
            height: 100%;
            background: rgba(244, 237, 138, 0.8);
            background: radial-gradient(#8a1d9e, #32013c);
        }

        .nav--menu-toggle {
            display: block;
            opacity: 1;
            animation: toggleMenu 1s;
        }

        @at-root {
            @keyframes toggleMenu {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        ul {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: auto auto auto;
            justify-items: center;
            align-content: center;
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
            cursor: pointer;
            text-decoration: none;
            color: rgba(244, 237, 138, 0.8);
            font-size: 3.2rem;
            grid-column: 2/3;
            padding: 10px;
            margin: 5px;

            @media (min-width: $sm) {
                font-size: 3.8rem;
                padding: 20px;
                margin: 5px;
            }

            &:hover {
                color: rgba(244, 237, 138, 1)
            }
        }

        .nav--link {
            color: rgba(244, 237, 138, 0.8);
            text-decoration: none;

            &:hover {
                color: rgba(244, 237, 138, 1)
            }
            &:before, &:after {
                content: none;
            }
        }

        .nav--toggle {
            cursor: pointer;
            position: fixed;
            top: 20px;
            right: 20px;
            width: 50px;
            height: 50px;
        }

        .nav--burger {
            position: absolute;
            top: 23px;
            left: 10px;
            height: 4px;
            border-radius: 2px;
            width: 30px;
            background-color: #ef00ff;

            &:before, &:after {
                position: absolute;
                content: ' ';
                height: 4px;
                border-radius: 2px;
                width: 30px;
                background-color: #ef00ff;
            }

            &:before {
                top: -10px
            }

            &:after {
                top: 10px
            }

        }
    }

    // Facets

    // States
}
