@import "./font.scss";

h1 {
    font-weight: normal;
    font-size: 2.8em;
    letter-spacing: 0.1em;
    margin: 0;
    margin-bottom: 0.5em;
    line-height: 1.1;

    @media (min-width: $sm) {
        font-size: 4.8em;
        letter-spacing: 0.1em;
    }
}

h2 {
    color: #f4ed8a;
    font-weight: normal;
    font-size: 2.0em;
    margin: 0;
    padding-bottom: 10px;
    line-height: 1.1;

    @media (min-width: $sm){
        font-size: 2.8em;
    }
}

h3 {
    color: #f4ed8a;
    font-weight: normal;
    font-size: 2em;
    margin: 0;
    line-height: 1.1;

    @media (min-width: $sm) {
        font-size: 2.8em;
    }
}

p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: .2rem;
    hyphens: auto;

    @media (min-width: $sm) {
        font-size: 1.8rem;
        line-height: 2.4rem;
        letter-spacing: .3rem;
    }
}

.textCenter {
    text-align: center;
}
