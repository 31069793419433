.header {
    // Vars

    // Support

    // Module
    & {
        min-height: 100vh;
        background: #8661a2;
        background: radial-gradient(at 50% 26%, #8661a2 0%, #181b31 100%);
        padding-bottom: 66%;

        .header--grid-container {
            @media (max-width: $sm) {
                display: grid;
                grid-template-columns: auto;
            }
            align-items: center;
            justify-items: center;
            height: 100vh;
        }

        .header--title {
            padding-top: 60px;
        }

        .header--grid-content {
            @media (min-width: $sm) {
                grid-column: 2/3;
            }
            text-align: center;
            margin-bottom: 60px;
        }

        .header--transition {
            height: 200px;
            width: 100%;
            background: #8661a2;
            background: radial-gradient(at 50% 50%, #8661a2 0%, #181b31 100%);
            mask-image: url(../../Media/schatten_01_b.png);
        }

        .header--keyViz {
            width: 80%;

            @media (min-width: $sm) {
                width: auto;
            }
        }

    }

    // Facets

    // States
}
