.edit-page {
    // Vars

    // Support

    // Module
    &.edit-page--logout {
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 5000;
        width: auto;
    }

    // Facets

    // States
}
