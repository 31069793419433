@import "../scss_utils/sizes";
@import "../scss_utils/typo.scss";
@import "../scss_utils/grid.scss";
@import "../scss_utils/bg.scss";
@import "../scss_utils/font.scss";
@import "about/_about.scss";
@import "events/events";
@import "header/_header.scss";
@import "work/_work.scss";
@import "method/_method.scss";
@import "contact/contact.scss";
@import "contact-form/contact-form.scss";
@import "address/_address.scss";
@import "nav/_nav.scss";
@import "colorpicker/_colorpicker.scss";
@import "edit-page/_edit-page.scss";
@import "login/_login.scss";

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {

    @include dinMed();

    button {
        @include dinMed();
    }

    a {
        display: inline-block;
        @include dinMed();
        color: #f4ed8a;
        text-decoration: none;

        &:before {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 11%;
            left: 0;
            background-color: #f4ed8a;
        }

        &:after {
            content: ' ';
            position: absolute;
            left: -1%;
            top: 0%;
            height: 89%;
            width: 100%;
            opacity: 0.2;
        }

        &:hover {
            &:before {
                left: -1%;
                transition: all 200ms;

            }

            &:after {
                background-color: #f4ed8a;
                transition: all 200ms;
            }
        }
    }

    * {
        position: relative;
        box-sizing: border-box;
    }

    ::after, ::before {
        box-sizing: border-box;
    }

    .button-base {
        width: 100%;
        cursor: pointer;
        @media (min-width: $sm) {
            width: auto;
        }

        border: none;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 2rem;
        padding: 10px 20px 8px 20px;
        color: #f4ed8a;
        border-radius: 5px;
        box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);

        &:hover {
            outline-width: 0;
            background: rgba(0, 0, 0, 0.5);
            box-shadow: 0 4px 15px 1px rgba(0, 0, 0, 0.15);

        }

        &:focus {
            outline-width: 0;
        }

        &:active {
            outline-width: 0;
            background: rgba(0, 0, 0, 0.8);
            box-shadow: 0 6px 15px 1px rgba(0, 0, 0, 0.20);
        }
    }

    .edit {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin-top: 10px;
    }

    .add-entry {
        width: 100%;
        margin-top: 50px;
    }

    .delete-entry {
        margin-top: 10px;
    }

}

.full-width {
    width: 100%;
}


article {
    z-index: 1000;
}
